<template>
  <Sidebar ref="sidebar" :title="$tuf('domains')">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <FieldsGenerator
        v-if="ready"
        :errors="errors"
        :fields="fields"
        :data="form"
        :entryId="entryId"
        entryType="websitedomains"
        :fileCollection="form.media"
        class="relative z-1"
      />

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="button"
            class="button button--outlined relative mr-4"
            :class="{ 'opacity-25': loading && submittedBy !== 'save' }"
            :disabled="loading"
            @click="save(false, 'save')"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save' }">
              {{ $tuf('save') }}
            </span>
            <span v-if="loading && submittedBy === 'save'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted } from '@vue/composition-api';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import Sidebar from '@/modules/core/views/components/Sidebar';
import useWebsite from '@/compositions/useWebsite';
import { project } from '@/compositions/useProjects';
import { asyncForEach } from '@/providers/helpers';
import difference from 'lodash/difference';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'DomainEdit',

  components: {
    FieldsGenerator,
    LoadingIndicator,
    Overlay,
    Sidebar,
  },

  setup(props, { refs, root }) {
    const { project_id, website_id } = root.$route.params;
    const { fetchDomains, updateDomain, createDomain, deleteDomain } = useWebsite();
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {},
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
      activeDomainIds: [],
    });

    const fields = [
      {
        name: 'domains',
        type: 'repeater',
        label: 'Domeinen',
        config: {
          defaultLabel: 'Nieuw domein',
          max: 5,
        },
        fields: [
          {
            name: 'host',
            type: 'text',
            label: 'Hostname',
            placeholder: `bijv. ${project.value.slug}.nl`,
          },
          {
            name: 'is_secure',
            type: 'check',
            label: 'Deze website is beveiligd met een SSL verbinding (https).',
          },
          {
            name: 'is_primary',
            type: 'check',
            label: 'Dit domein gebruiken als hoofddomein.',
          },
          {
            name: 'is_redirect',
            type: 'check',
            label: 'Redirect naar hoofddomein.',
          },
          {
            name: 'allow_robots',
            type: 'check',
            label: 'Toestaan deze website te mogen opnemen in zoekresultaten.',
          },
        ],
      },
    ];

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const domains = await fetchDomains(project_id, website_id);
      const projectDomains = domains.filter(domain => domain.website !== null);

      state.form = {
        domains: projectDomains,
      };

      state.activeDomainIds = projectDomains.map(domain => domain.id);

      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        const stillActiveDomains = state.form.domains.filter(domain => domain.id !== undefined);
        const stillActiveDomainIds = stillActiveDomains.map(domain => domain.id);
        const deletedDomains = difference(state.activeDomainIds, stillActiveDomainIds);

        await asyncForEach(state.form.domains, async (domainData) => {
          if (domainData.id) {
            await updateDomain(project_id, domainData.id, {
              host: domainData.host,
              is_secure: domainData.is_secure,
              is_primary: domainData.is_primary,
              is_redirect: domainData.is_redirect,
              allow_robots: domainData.allow_robots,
            });
          } else {
            await createDomain(project_id, website_id, domainData);
          }
        });

        if (deletedDomains.length > 0) {
          await asyncForEach(deletedDomains, async (domain_id) => {
            await deleteDomain(project_id, domain_id);
          });
        }

        root.$notify({ type: 'success', title: 'Succes', text: 'Domeinen opgeslagen' });

        state.loading = false;

        commitChanges(state, 'form');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(state),
      save,
      entryId: null,
      fields,
    };
  },
};
</script>
